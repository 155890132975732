.navbar{
  height: 90px;
  font-family: 'Poppins', serif;
  /* width: 80%; */
}

/* Logo */
.navbar-brand{
  margin-left: var(--bs-navbar-brand-margin-end);
}

.navbar-brand img{
  max-width: 200px;
  object-fit: cover;
}

/* Menu */
.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav a{
  margin: 0 1%;
  color:  #423127;
}

/* Online Order Menu Button */
.nav-btn{
  border: 2px solid #423127;
  color: #423127;
  border-radius: 30px;
  margin-right: 3%;
  padding: 10px 30px;
}

.nav-btn:hover{
  background-color: #423127;
  color: #fff;
}

/* Mobile Toggle Menu */
.navbar-toggler{
    border: none;
}

.navbar-toggler:focus{
    outline: none;
}

.offcanvas-header{
  height:90px;
}

.offcanvas-header .btn-close{
  margin-right: 2%;
}

.offcanvas.offcanvas-top {
  height: 100vh;
}

@media only screen and (max-width: 992px) {
  .offcanvas-body{
    font-size: 25px;
    text-align: center;
  }

  .nav-btn{
    margin-right: 0px;
    margin-top: 1rem;
    font-size: 25px;
  }
}