footer{
    font-family: 'Poppins', sans-serif;
    background-color: #2F2E2E;
    color: white;
}

footer p{
    text-align: center;
}

footer .row{
    margin-top: 1rem;
}

#copyright a{
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 768px) {
    footer .row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer .col{
        text-align: center;
        width: 90%;
    }

}