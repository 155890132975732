/* Common */
.main{
    margin-top:90px;
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    background-color: #171717;
    color: white;
}

.container{
    align-self: center;
    max-width: 1280px;
}

.arrow-wrapper img{
    width: 25px !important;
    opacity: 1 !important;
}

.link-btn{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 2px solid white;
    border-radius: 30px;
    padding: 10px 30px;
    color: white;
}

.link-btn:hover{
    border: 2px solid white;
    background-color: #423127;
}

.section1,
.section2,
.section3,
.section4,
.section5{
    margin: 0;
}

/* Font size */
h1{
    font-size: 55px;
    font-weight: bold;
    text-align: center;
}

h2{
    font-size: 40px;
    font-weight: bold;
}

h3{
    font-size: 30px;
    font-weight: bold;
}

h4{
    font-size: 25px;
    font-weight: bold;
}

h5{
    font-size: 20px;
    font-weight: bold;
}

p{
    font-size: 15px;
}

/* Text Overlay on Image */
.overlay-container{
    width: 100%;
    position: relative;
}

.overlay-centered-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.delivery-wrapper{
    width: 80%;
}
.delivery-wrapper{
    display: flex;
    justify-content: space-around; 
    align-items: center;
}

.delivery-wrapper a{
    border-radius: 15px;
    overflow: hidden;
    width: 140px;
    height: 60px;
}

.delivery-wrapper a img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Section1: Main */
.main-image-container {
    max-height: 700px;
    overflow: hidden;
}

.main-image-container video{
    width: 100%;
}


.overlay-button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay-button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 15px 85px;
    background-color: #fff;
}

.overlay-button{
    color: #33343F;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-decoration: none;
}

/* Section2: About */
.section2{
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.section2 div{
    padding: 1%;
}

.section2 #header-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section2 #header-wrapper div{
    width: 60%;
}

.section2 #logo{
    max-width: 300px;
}

.section2 .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.section2 .img-wrapper .row{
    width: 100%;
    height: 200px;
}

.section2 .img-wrapper .row img{
    height: 100%;
    object-fit: cover;
    object-position: 0% 50%;
}

.content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Section3: Order Online */
.section3 .overlay-container{
    margin-top: 3rem;
    overflow: hidden;
}

.section3 .overlay-container img{
    max-height: 550px;
    width: 100%;
    object-fit: cover;
    opacity: 0.62;
}

/* Section5: See Menu & Location */
.section5 {
    max-height: 750px;
    background-color: #423128;
    overflow: hidden;
}

.section3 .overlay-container{
    overflow: hidden;
}

.map-container {
    width: 100%;
    /* height: 100vh; */
}

.section5 .overlay-container img{
    max-height: 750px;
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 1200px) {
    h1{
        font-size: 45px;
        font-weight: bold;
        text-align: center;
    }
    
    h2{
        font-size: 35px;
        font-weight: bold;
    }
    
    h3{
        font-size: 30px;
        font-weight: bold;
    }
    
    h4{
        font-size: 25px;
        font-weight: bold;
    }
    
    h5{
        font-size: 20px;
    }
    
    p{
        font-size: 18px;
    }

    .main-image-container{
        max-height: 800px;
    }

    .main-image-container img{
        width: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width: 992px) {
    h1{
        font-size: 40px;
        font-weight: bold;
        text-align: center;
    }
    
    h2{
        font-size: 30px;
        font-weight: bold;
    }
    
    h3{
        font-size: 25px;
        font-weight: bold;
    }
    
    h4{
        font-size: 20px;
        font-weight: bold;
    }
    
    h5{
        font-size: 15px;
    }
    
    p{
        font-size: 12px;
    }

    .row{
        flex-direction: column;
    }

    .overlay-button{
        padding: 8px 22px;
    }

    .overlay-button{
        font-size: 13px;
    } 

    .section2 img{
        max-height: 260px;
        object-fit: cover;
    }

    .delivery-wrapper a{
        border-radius: 15px;
        overflow: hidden;
        width: 130px;
        height: 50px;
        margin: 5px;
    }
}

@media only screen and (max-width: 768px) {
    h1{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }
    
    h2{
        font-size: 22px;
        font-weight: bold;
    }
    
    h3{
        font-size: 20px;
        font-weight: bold;
    }
    
    h4{
        font-size: 18px;
        font-weight: bold;
    }
    
    h5{
        font-size: 15px;
    }
    
    p{
        font-size: 12px;
    }

    .row{
        flex-direction: column;
    }

    .link-btn{
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: #423127;
        padding: 10px 30px;  
        font-size: 12px;
    }

    .main-image-container{
        max-height: 400px;
    }

    .main-image-container img{
        width: 100%;
        object-fit: cover;
    }

   
    .section5 .overlay-centered-text,
    .section1 .overlay-centered-text {
        width: 100%;
    }

    .delivery-wrapper{
        width: 80%;
    }

    .delivery-wrapper a {
        width: 140px;
        height: 40px;
        margin: 3px;
    }

    .section2 #header-wrapper #logo img{
        width: 60%;
    }
    .section2 #header-wrapper div {
        width: 100%;
    }

    .section2 .content-wrapper{
        text-align: center !important;
    }

    .section3 .overlay-container{
        height: 300px;
        margin-top: 0rem;
        overflow: hidden;
    }
    
    .section3 .overlay-container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.62;
    }

    .section3 .overlay-centered-text{
        width: 90%;
    }

    .section5 .col{
        display: flex;
        /* flex-direction: column; */
    }
}